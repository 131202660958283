import { mainClient, mapClient } from 'apollo/client';
import {
  GET_REAL_ESTATE_CONFIGURATIONS,
  GetRealEstateConfigurationsData,
  RealEstateConfiguration,
} from 'graphql/main/queries/get-real-estate-configurations';
import {
  GET_INTERNAL_REAL_ESTATE_CONFIGURATIONS,
  GetInternalRealEstateConfigurationsData,
  InternalRealEstateConfigurationType,
} from 'graphql/main/queries/get-internal-real-estate-configuration';
import {
  GET_LIST_PROVINCES,
  GetListProvincesData,
  Province,
} from 'graphql/map/queries/get-list-provinces';
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

interface Props {
  major?: RealEstateConfiguration;
  provinces?: Province[];
  projectConfiguration?: InternalRealEstateConfigurationType;
}

const ConfigurationContext = createContext<Props>({});

export const useConfigurationContext = () => useContext(ConfigurationContext);

const ConfigurationProvider = ({ children }: PropsWithChildren) => {
  const [major, setMajor] = useState<RealEstateConfiguration>();
  const [provinces, setProvinces] = useState<Province[]>();
  const [projectConfiguration, setProjectConfiguration] =
    useState<InternalRealEstateConfigurationType>();

  useEffect(() => {
    (async () => {
      const [
        { data: getRealEstateConfigurationsData },
        { data: getListProvincesData },
        { data: getProjectConfigurationsData },
      ] = await Promise.all([
        mainClient.query<GetRealEstateConfigurationsData>({
          query: GET_REAL_ESTATE_CONFIGURATIONS,
        }),
        mapClient.query<GetListProvincesData>({
          query: GET_LIST_PROVINCES,
        }),
        mainClient.query<GetInternalRealEstateConfigurationsData>({
          query: GET_INTERNAL_REAL_ESTATE_CONFIGURATIONS,
        }),
      ]);
      const { getRealEstateConfigurations: major } = getRealEstateConfigurationsData;
      const { getListProvinces: provinces } = getListProvincesData;
      const { getInternalRealEstateConfigurations: projectConfiguration } =
        getProjectConfigurationsData;
      setMajor(major);
      setProvinces(provinces);
      setProjectConfiguration(projectConfiguration);
    })();
  }, []);

  return (
    <ConfigurationContext.Provider value={{ major, provinces, projectConfiguration }}>
      {children}
    </ConfigurationContext.Provider>
  );
};

export default ConfigurationProvider;
