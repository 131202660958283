import { filter, isEqual, size, toString } from 'lodash';
import { useState } from 'react';

export interface ToastModel {
  id?: string;
  type?: 'success' | 'information' | 'warning' | 'error';
  content?: string;
}

export const useToast = () => {
  const [toasts, setToasts] = useState<ToastModel[]>([]);

  const addToast = (type: 'success' | 'information' | 'warning' | 'error', content: string) => {
    const id = toString(Date.now());
    setToasts((previousToasts) => [
      ...previousToasts,
      {
        id,
        type,
        content,
      },
    ]);
    setTimeout(
      () => {
        removeToast(id);
      },
      isEqual(size(toasts), 0) ? 1.5 * 1000 : size(toasts) * 1.5 * 1000,
    );
  };
  const removeToast = (id: string) => {
    setToasts((previousToasts) => filter(previousToasts, (toast) => !isEqual(toast.id, id)));
  };

  return {
    toasts,
    addToast,
    removeToast,
  };
};
