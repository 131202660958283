import { Popover } from '@headlessui/react';
import { map } from 'lodash';
import { useState } from 'react';
import { RiShareForwardFill } from 'react-icons/ri';
import { TbDotsVertical } from 'react-icons/tb';
import MessageReaction from './message-reaction';

interface Props {
  id?: string;
}

const MessageAction = ({ id }: Props) => {
  const handleOnMakeAppointment = () => {};
  const handleOnCopyMessage = () => {};
  const handleOnForwardMessage = () => {};
  const handleOnDeleteMessage = () => {};
  const handleOnRecallMessage = () => {};
  const handleOnReplyMessage = () => {};

  const messageActions = [
    {
      label: 'Tạo lịch hẹn',
      onClick: () => handleOnMakeAppointment(),
    },
    {
      label: 'Copy tin nhắn',
      onClick: () => handleOnCopyMessage(),
    },
    {
      label: 'Chuyển tiếp tin nhắn',
      onClick: () => handleOnForwardMessage(),
    },
    {
      label: 'Xóa ở phía bạn',
      onClick: () => handleOnDeleteMessage(),
    },
    {
      label: 'Thu hồi tin nhắn',
      onClick: () => handleOnRecallMessage(),
    },
  ];

  return (
    <div
      id='message-action'
      className='hidden items-center space-x-[8px] pr-[10px] group-hover:flex'
    >
      <Popover className='relative'>
        <Popover.Button type='button'>
          <TbDotsVertical className='min-h-[22px] min-w-[22px] text-text2' />
        </Popover.Button>
        <Popover.Panel className='absolute bottom-[40px] left-1/2 flex min-w-[138px] -translate-x-1/2 flex-col space-y-[12px] rounded-[8px] bg-paper py-[12px] shadow-4'>
          {map(messageActions, (messageAction, messageActionIndex) => (
            <button
              key={messageActionIndex}
              type='button'
              className='flex h-[22px] w-full items-center px-[12px] transition duration-[200ms] ease-in-out hover:bg-secondary'
              onClick={messageAction.onClick}
            >
              <span className='whitespace-nowrap text-[12px] leading-[15px]'>
                {messageAction.label}
              </span>
            </button>
          ))}
        </Popover.Panel>
        <div className='absolute top-[-15px] left-1/2 -translate-x-1/2 border-x-[10px] border-t-[8px] border-x-transparent border-t-paper' />
      </Popover>
      <MessageReaction />
      <button type='button' onClick={handleOnReplyMessage}>
        <RiShareForwardFill className='min-h-[22px] min-w-[22px] scale-x-[-1] text-text2' />
      </button>
    </div>
  );
};

export default MessageAction;
