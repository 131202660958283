import { MdClear } from 'react-icons/md';
import SectionLeft from './section-left';
import SectionRight from './section-right';

interface Props {
  isShowing?: boolean;
  onClose?: () => void;
}

const ChatBox = ({ isShowing, onClose }: Props) => {
  const handleOnMouseOver = () => {
    document.body.style.overflow = 'hidden';
  };
  const handleOnMouseLeave = () => {
    document.body.style.overflow = 'auto';
  };
  const handleOnCloseModal = () => {
    onClose?.();
  };

  return (
    <div
      id='chat-box'
      className={`fixed right-[64px] bottom-0 z-[100] origin-bottom-right scale-0 transition duration-[200ms] ease-in-out ${
        isShowing ? 'scale-100' : ''
      }`}
      onMouseOver={handleOnMouseOver}
      onMouseLeave={handleOnMouseLeave}
    >
      <div className='flex h-[592px] w-[692px] flex-col overflow-hidden rounded-t-[8px] bg-paper shadow-15'>
        <div className='flex min-h-[40px] items-center justify-between bg-secondary px-[16px]'>
          <span className='cursor-default select-none text-[16px] font-[600] leading-[20px] text-primary'>
            Tin nhắn
          </span>
          <button type='button' onClick={handleOnCloseModal}>
            <MdClear className='min-h-[24px] min-w-[24px] text-text2' />
          </button>
        </div>
        <div className='flex h-full overflow-hidden'>
          <SectionLeft />
          <SectionRight />
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
