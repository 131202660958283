import { setupApollo } from 'apollo/client';
import Modules from 'components/desktop/modules';
import AuthenticationProvider, {
  useAuthenticationContext,
} from 'components/providers/authentication-provider';
import ConfigurationProvider from 'components/providers/configuration-provider';
import { useResponsive } from 'hooks';
import 'leaflet/dist/leaflet.css';
import { isEqual } from 'lodash';
import moment from 'moment-timezone';
import 'moment/locale/vi';
import type { AppProps } from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import 'react-rangeslider/lib/index.css';
import 'styles/blog.css';
import 'styles/chat.css';
import 'styles/globals.css';
import 'styles/map.css';
import 'styles/slider.css';
import 'styles/swiper.css';
import 'swiper/css';
import 'swiper/css/pagination';

moment.tz.setDefault('Asia/Ho_Chi_Minh');
NProgress.configure({ showSpinner: false });
Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});
Router.events.on('routeChangeError', () => {
  NProgress.done();
});

const App = (props: AppProps) => {
  const { Component, pageProps } = props;
  const { currentUser } = useAuthenticationContext();
  const responsive = useResponsive();

  setupApollo();

  return (
    <AuthenticationProvider>
      <ConfigurationProvider>
        {currentUser ? (
          isEqual(responsive, 'mobile') ? null : isEqual(responsive, 'desktop') ? (
            <Modules />
          ) : null
        ) : null}
        <Component {...pageProps} />
      </ConfigurationProvider>
    </AuthenticationProvider>
  );
};

export default App;
