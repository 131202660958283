import { useState } from 'react';

export const useConfirmationModal = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [data, setData] = useState<any>();

  const onToggle = (confirmationData?: any) => {
    setIsShowing(!isShowing);
    setData(confirmationData);
  };

  return { isShowing, data, onToggle };
};
