import { gql } from '@apollo/client';
import { B2CUser } from './get-representative-brokers';

export interface UserData {
  user?: B2CUser;
}

export const USER = gql`
  query user @api(name: users) {
    user {
      avatar {
        ... on FileWithFullUrls {
          fileId
          originalUrl
        }
      }
      id
      fullName
      phone
      secondPhone
      email
      phones
      type
      statusProfile
      memberId
      statusProfileCompany
      birthDate
      gender
      province {
        name
        id
      }
      company
      idNumber
    }
  }
`;
