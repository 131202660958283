import { gt, gte, max } from 'lodash';
import { useState, useEffect } from 'react';

export const useScroll = () => {
  const [isScrollEnd, setIsScrollEnd] = useState(false);
  const [direction, setDirection] = useState<'down' | 'up' | undefined>(undefined);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    let previousPosition = window.pageYOffset;

    const handleScroll = () => {
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const documentHeight = max([
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight,
      ]);

      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      setIsScrollEnd(gte(scrollPosition + windowHeight, documentHeight));
    };
    const updateScrollDirection = () => {
      const currentPosition = window.pageYOffset;
      const distance = Math.abs(currentPosition - previousPosition);

      if (gte(distance, 0)) {
        setDirection(gt(currentPosition, previousPosition) ? 'down' : 'up');
        previousPosition = currentPosition;
      }
    };
    const updatePosition = () => {
      setPosition(window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', updateScrollDirection);
    window.addEventListener('scroll', updatePosition);
    updatePosition();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', updateScrollDirection);
      window.removeEventListener('scroll', updatePosition);
    };
  }, []);

  return { isScrollEnd, direction, position };
};
