import { Popover } from '@headlessui/react';
import { map } from 'lodash';
import Image from 'next/image';
import { BsEmojiSmile } from 'react-icons/bs';

const MessageReaction = () => {
  const messageReactions = [
    {
      iconUrl: '/icons/reactions/thumbs-up-reaction.svg',
      value: 'LIKE',
    },
    {
      iconUrl: '/icons/reactions/red-heart-reaction.svg',
      value: 'LOVE',
    },
    {
      iconUrl: '/icons/reactions/smiley-face-reaction.svg',
      value: 'HAHA',
    },
    {
      iconUrl: '/icons/reactions/surprise-face-reaction.svg',
      value: 'WOW',
    },

    {
      iconUrl: '/icons/reactions/crying-face-reaction.svg',
      value: 'SAD',
    },
    {
      iconUrl: '/icons/reactions/pouting-face-reaction.svg',
      value: 'ANGRY',
    },
  ];

  const handleOnClickReaction = (action: string) => {
    switch (action) {
      case 'LIKE':
        break;
      case 'LOVE':
        break;
      case 'HAHA':
        break;
      case 'WOW':
        break;
      case 'SAD':
        break;
      case 'ANGRY':
        break;
      default:
        break;
    }
  };

  return (
    <Popover id='message-reaction' className='relative'>
      <Popover.Button type='button'>
        <BsEmojiSmile className='min-h-[16px] min-w-[16px] text-text2' />
      </Popover.Button>
      <Popover.Panel
        className='shadow-9 absolute bottom-[-40px] left-1/2 flex min-h-[40px] -translate-x-1/2 items-center space-x-[16px] rounded-full bg-paper
px-[12px]'
      >
        {map(messageReactions, (messageReaction, messageReactionIndex) => (
          <button
            key={messageReactionIndex}
            type='button'
            className='relative h-[20px] w-[20px] duration-[200ms] hover:origin-bottom hover:scale-[1.5]'
            onClick={() => {
              handleOnClickReaction(messageReaction.value);
            }}
          >
            <Image
              src={messageReaction.iconUrl}
              alt={messageReaction.iconUrl}
              objectFit='cover'
              layout='fill'
            />
          </button>
        ))}
      </Popover.Panel>
    </Popover>
  );
};

export default MessageReaction;
