import { ApolloLink, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';
import { createUploadLink } from 'apollo-upload-client';
import axios from 'axios';
import { AuthenticationModel } from 'components/providers/authentication-provider';
import { getCookie } from 'cookies-next';
import {
  API_ENDPOINT1,
  API_ENDPOINT2,
  BLOG_API_ENDPOINT,
  MAP_API_ENDPOINT,
  SOCKET_API_ENDPOINT,
} from 'globalConstants';
import { createClient } from 'graphql-ws';
import { isEqual, toString } from 'lodash';

const isBrowser = !isEqual(typeof window, 'undefined');

export const baseLink = setContext((_, { headers }) => ({
  headers: {
    ['Accept-Language']: 'vi',
    ...headers,
  },
}));
export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) =>
      console.error(`[GraphQL error]: Message: ${message} Path: ${path}`),
    );
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});
export const mainLink = ApolloLink.from([
  new MultiAPILink({
    endpoints: {
      users: `${API_ENDPOINT2}/users`,
      media: `${API_ENDPOINT1}/media`,
      realEstate: `${API_ENDPOINT1}/realEstate`,
      common: `${API_ENDPOINT1}/common`,
      chat: `${API_ENDPOINT1}/chat`,
      biha: `${API_ENDPOINT2}/biha`,
    },
    createHttpLink,
  }),
]);
export const uploadLink = createUploadLink({
  uri: `${API_ENDPOINT1}/media/graphql`,
  fetch: buildAxiosFetch(axios, (config, _, init: any) => ({
    ...config,
    onUploadProgress: init.onUploadProgress,
  })),
});
export const mapLink = createHttpLink({
  uri: `${MAP_API_ENDPOINT}/graphql`,
});
export const chatLink = isBrowser
  ? toString(getCookie('authentication'))
    ? new GraphQLWsLink(
        createClient({
          url: `${SOCKET_API_ENDPOINT}/chat/subscriptions`,
          connectionParams: {
            authToken: (JSON.parse(toString(getCookie('authentication'))) as AuthenticationModel)
              .accessToken,
          },
        }),
      )
    : undefined
  : undefined;
export const blogLink = createHttpLink({
  uri: `${BLOG_API_ENDPOINT}/graphql`,
});

export const createAuthenticationLink = (accessToken?: string) =>
  setContext((_, { headers }) => ({
    headers: {
      ...headers,
      ['Accept-Language']: 'vi',
      ['Authorization']: `Bearer ${accessToken}`,
    },
  }));
