import { useReactiveVar } from '@apollo/client';
import { useFlexQuery, useFlexSubscription } from 'hooks';
import { fill, filter, isEmpty, isEqual, map, size } from 'lodash';
import { useEffect, useState } from 'react';
import { ImSpinner6 } from 'react-icons/im';
import InfiniteScroll from 'react-infinite-scroll-component';
import { conversationsVar } from '../../../../../../../apollo/store';
import {
  CONVERSATION_UPDATED,
  ConversationUpdatedData,
} from '../../../../../../../graphql/chat/subscriptions/conversation-updated';
import {
  CONVERSATIONS,
  Conversation as ConversationModel,
  ConversationsData,
  ConversationsVariables,
} from '../../../../../../../graphql/main/queries/conversations';
import Conversation, { ConversationSkeleton } from './conversation';

const DEFAULT_SKELETON_CONVERSATIONS = 5;
const DEFAULT_LIMIT_CONVERSATIONS = 10;
const DEFAULT_OFFSET_CONVERSATIONS = 0;

const ConversationList = () => {
  const [hasMore, setHasMore] = useState(true);
  const {
    data: conversationsData,
    loading: loadingConversations,
    fetchMore: fetchMoreConversations,
  } = useFlexQuery<ConversationsData, ConversationsVariables>('main', CONVERSATIONS, {
    variables: {
      limit: DEFAULT_LIMIT_CONVERSATIONS,
      offset: DEFAULT_OFFSET_CONVERSATIONS,
    },
  });
  const conversations = useReactiveVar(conversationsVar);
  const conversationsSize = size(conversations);
  const { data: conversationUpdatedData } = useFlexSubscription<ConversationUpdatedData>(
    'chat',
    CONVERSATION_UPDATED,
  );

  const handleOnLoadMore = () => {
    fetchMoreConversations({
      variables: {
        limit: DEFAULT_LIMIT_CONVERSATIONS,
        offset: conversationsSize,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (isEqual(size(fetchMoreResult.conversations?.items), 0)) {
          setHasMore(false);
        }

        return !fetchMoreResult || isEqual(size(fetchMoreResult.conversations?.items), 0)
          ? {
              ...previousResult,
              conversations: {
                ...previousResult.conversations,
                items: [...(conversations ?? [])],
              },
            }
          : {
              ...fetchMoreResult,
              conversations: {
                ...fetchMoreResult.conversations,
                items: [...(conversations ?? []), ...(fetchMoreResult.conversations?.items ?? [])],
              },
            };
      },
    });
  };

  useEffect(() => {
    conversationsVar(conversationsData?.conversations?.items);
  }, [conversationsData]);
  useEffect(() => {
    if (conversationUpdatedData?.conversationUpdated) {
      conversationsVar([
        conversationUpdatedData.conversationUpdated,
        ...filter(
          conversations,
          (conversation) =>
            !isEqual(conversation.id, conversationUpdatedData.conversationUpdated?.id),
        ),
      ]);
    }
  }, [conversationUpdatedData]);

  return loadingConversations ? (
    <div className='flex flex-col'>
      {map(fill(Array(DEFAULT_SKELETON_CONVERSATIONS), {}), (_, skeletonIndex) => (
        <ConversationSkeleton key={skeletonIndex} />
      ))}
    </div>
  ) : isEmpty(conversations) ? (
    <div className='flex h-full items-center justify-center'>
      <span className='italic text-text2'>Không tìm thấy cuộc hội thoại</span>
    </div>
  ) : (
    <div id='conversation-list' className='chat flex flex-col overflow-y-auto'>
      <InfiniteScroll
        dataLength={conversationsSize}
        hasMore={hasMore}
        scrollableTarget='conversation-list'
        loader={
          <div key={0} className='flex h-[63px] items-center justify-center'>
            <ImSpinner6 className='min-h-[20px] min-w-[20px] animate-spin text-primary' />
          </div>
        }
        next={handleOnLoadMore}
      >
        {map(conversations, (conversation: ConversationModel, conversationIndex) => (
          <Conversation
            key={`${conversation.id}-${conversationIndex}`}
            conversation={conversation}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default ConversationList;
