import { useReactiveVar } from '@apollo/client';
import { Popover } from '@headlessui/react';
import { useAuthenticationContext } from 'components/providers/authentication-provider';
import EmojiPicker from 'emoji-picker-react';
import { useFlexMutation } from 'hooks';
import { filter, first, isEmpty, isEqual, map, toString, trim } from 'lodash';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { BsEmojiSmile } from 'react-icons/bs';
import { IoImagesOutline } from 'react-icons/io5';
import { RiSendPlaneFill } from 'react-icons/ri';
import { conversationsVar, selectedConversationVar } from '../../../../../../../apollo/store';
import {
  RecipientType,
  SEND_MESSAGE,
  SendMessageData,
  SendMessageVariables,
} from '../../../../../../../graphql/main/mutations/send-message';

const defaultValues = {
  message: '',
};

const MessagePlace = () => {
  const { currentUser } = useAuthenticationContext();
  const conversations = useReactiveVar(conversationsVar);
  const selectedConversation = useReactiveVar(selectedConversationVar);
  const form = useForm({
    defaultValues,
  });
  const message = form.register('message');
  const messageWatch = useWatch({ control: form.control, name: 'message' });
  const [sendMessage] = useFlexMutation<SendMessageData, SendMessageVariables>(
    'main',
    SEND_MESSAGE,
  );
  const [mutationFlag, setMutationFlag] = useState(false);

  const onSubmit = async (data: any) => {
    form.setValue('message', '');
    if (!isEmpty(trim(data.message))) {
      if (selectedConversation?.id) {
        await sendMessage({
          variables: {
            recipient: {
              conversationId: selectedConversation.id,
              recipientType: RecipientType.conversation,
            },
            message: {
              text: data.message,
            },
          },
        });
      } else {
        await sendMessage({
          variables: {
            recipient: {
              recipientType: RecipientType.users,
              userIds: map(
                filter(
                  selectedConversation?.participants,
                  (participant) => !isEqual(participant.userId, currentUser?.id),
                ),
                (participant) => toString(participant.userId),
              ),
            },
            message: {
              text: data.message,
            },
          },
        });
        setMutationFlag(!mutationFlag);
      }
    }
  };

  useEffect(() => {
    if (mutationFlag && !selectedConversation?.id) {
      conversationsVar(filter(conversations, (conversation) => Boolean(conversation.id)));
      selectedConversationVar(first(conversations));
      setMutationFlag(!mutationFlag);
    }
  }, [mutationFlag]);

  return (
    <FormProvider {...form}>
      <form id='message-place' autoComplete='off' onSubmit={form.handleSubmit(onSubmit)}>
        <div className='relative flex items-center space-x-[10px] border-t border-stroke bg-paper px-[10px] pt-[10px] pb-[20px]'>
          <div
            className='flex min-h-[42px] w-full items-center space-x-[12px] overflow-hidden rounded-[16px] border
        border-stroke px-[12px]'
          >
            <input
              {...message}
              autoFocus
              placeholder='Nhập nội dung tin nhắn'
              className='w-full bg-transparent py-[6px] text-[13px] leading-[16px]'
            />
            <button type='button'>
              <IoImagesOutline className='min-h-[16px] min-w-[16px] text-text2' />
            </button>
            <Popover>
              <Popover.Button className='flex items-center'>
                <BsEmojiSmile className='min-h-[16px] min-w-[16px] text-text2' />
              </Popover.Button>
              <Popover.Panel className='absolute bottom-full right-[28px]'>
                <EmojiPicker
                  searchDisabled
                  previewConfig={{
                    showPreview: false,
                  }}
                  onEmojiClick={(event) => {
                    form.setValue('message', messageWatch + event.emoji);
                  }}
                />
              </Popover.Panel>
            </Popover>
          </div>
          <button className='flex h-[40px] w-[40px] items-center justify-center rounded-full bg-secondary'>
            <RiSendPlaneFill className='min-h-[18px] min-w-[18px] text-primary' />
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default MessagePlace;
