import { Fragment } from 'react';
import Chat from './chat';

const Modules = () => (
  <Fragment>
    <Chat />
  </Fragment>
);

export default Modules;
