import { ApprovalStatusEnumType } from 'graphql/main/queries/get-real-estates';
import { values, zipObject } from 'lodash';

const vi = {
  desktop: {
    home: {
      banner: {
        slogan: {
          title: 'Kênh thông tin về bất động sản',
          subtile: 'Giúp bạn nhanh chóng tìm được ngôi nhà mà bạn luôn mong ước',
        },
        searchTool: {
          forBuy: 'Đang bán',
          forRent: 'Cho thuê',
        },
      },
    },
    detail: {
      type: 'Loại',
      fullAddress: 'Địa chỉ',
      hasAlley: 'Vị trí',
      alleyTurnCount: 'Số lần quẹo hẻm',
      frontHomeAlleyWidth: 'Độ rộng hẻm',
      width: 'Ngang',
      length: 'Dài',
      rear: 'Mặt hậu',
      area: 'Diện tích',
      price: 'Giá',
      floorCount: 'Số tầng',
      constructionArea: 'Diện tích sàn',
      bedroomCount: 'Số phòng ngủ',
      toiletCount: 'Số nhà vệ sinh',
      direction: 'Hướng',
      isNewConstruction: 'Hiện trạng',
      industryTrade: 'Ngành hàng',
      project: 'Dự án',
      hotelStar: 'Số sao',
      buildingType: 'Loại cao ốc',
      warehouseType: 'Chức năng nhà xưởng',
      block: 'Thuộc block/khu nào',
      onFloor: 'Vị trí tầng',
      clearanceArea: 'Diện tích thông thuỷ',
      investor: 'Chủ đầu tư',
      hasMezzanine: 'Có gác hay không',
      commission: 'Phí hoa hồng',
      roomCount: 'Số lượng phòng',
      hasSeaView: 'Có view biển hay không',
      warehouseRoomCount: 'Số phòng kho',
      bedRoomArea: 'Diện tích phòng ngủ',
    },
    detailProject: {
      projectType: 'Loại hình',
      expectedPrice: 'Giá bán dự kiến',
      country: 'Quốc gia',
      fullAddress: 'Địa chỉ',
      coordinates: 'Tọa độ',
      projectName: 'Tên dự án',
      projectArea: 'Tổng diện tích dự án',
      constructionProjectArea: 'Tổng diện tích xây dựng',
      projectScale: 'Quy mô dự án',
      complexProjectType: 'Loại dự án',
      totalBlock: 'Số lượng tòa nhà',
      floorCountPerBlockRange: 'Số tầng các block',
      localProjectUtilities: 'Tiện tích nội khu dự án',
      ownershipTime: 'Thời gian sở hữu',
      startYear: 'Năm khởi công',
      expectedCompletionYear: 'Thời gian hoàn thành (Dự kiến)',
      investorName: 'Chủ đầu tư',
      constructionGeneralContractor: 'Tổng thầu xây dựng',
      projectOverview: 'Tổng quan dự án',
      investment: 'Vốn đầu tư',
      standardStar: 'Chuẩn sao',
      typeAreas: 'Loại diện tích',
      totalZone: 'Tổng số khu',
      totalBackground: 'Tổng số nền',
      floorCanBuild: 'Số tầng có thể xây',
      directions: 'Hướng',
      constructionForm: 'Hình thức xây dựng',
      totalWarehouse: 'Tổng số kho bãi',
      totalWorkshops: 'Tổng số nhà xưởng toàn dự án',
      floorRange: 'Số lượng tầng',
      totalBasements: 'Số lượng hầm',
      totalAreaBuild: 'Tổng diện tích sàn xây dựng toàn dự án',
      legalLicense: 'Giấy phép pháp lý',
      legals: 'Pháp lý',
      projectLocation: 'Vị trí dự án',
      planAndModelApartment: 'Mặt bằng & căn hộ mẫu',
      paymentRoute: 'Lộ trình thanh toán',
      apartmentCount: 'Số lượng căn hộ',
      townhouseCount: 'Số lượng Nhà phố liền kề',
      independentVillaCount: 'Số lượng Biệt thự đơn lập',
      doubleVillaCount: 'Số lượng Biệt thự song lập',
      shopHouseCount: 'Số lượng Shophouse',
      condotelCount: 'Số lượng Condotel',
      offictelCount: 'Số lượng Officetel',
      penthouseCount: 'Số lượng Penthouse',
      commercialAreaCount: 'Số lượng khu thương mại',
      loanEstimate: 'Ước tính khoảng vay',
      projectUtilities: 'Tiện ích quanh dự án',
      fixedUtilities: 'Tiện ích cố định',
      utilities: 'Tiện ích',
      localUtilities: 'Tiện ích trong khu vực',
      projectViews: 'View',
      equipmentsAndServices: 'Thiết bị, dịch vụ',
      realEstateLocation: 'Vị trí bất động sản',
      realEstatesInProject: 'Các loại bất động sản trong dự án',
      projectInformation: 'Thông tin dự án',
      areaBlockInformation: 'Thông tin Block & khu',
      realEstate: {
        apartment: 'Căn hộ',
        independentVilla: 'Biệt thự đơn lập',
        townhouse: 'Nhà phố liền kề',
        doubleVilla: 'Biệt thự song lập',
        shophouse: 'Shophouse',
        condotel: 'Condotel',
        offictel: 'Offictel',
        penthouse: 'Penthouse',
        commercialArea: 'Khu thương mại',
        kiosk: 'Kiosk',
        detail: 'Thông tin chi tiết',
        bedroomCount: 'Số lượng phòng ngủ mỗi',
        bedroomArea: 'Diện tích mỗi phòng ngủ',
        toiletCount: 'Số lượng nhà vệ sinh mỗi',
        toiletArea: 'Diện tích mỗi nhà vệ sinh',
        internalRoadArea: 'Diện tích sân vườn, đường nội bộ',
        floorCount: 'Số tầng của',
        kioskCount: 'Số kiosk',
      },
      product: {
        type: 'Loại',
        fullAddress: 'Địa chỉ',
        houseNumber: 'Mã căn',
        hasAlley: 'Vị trí',
        alleyTurnCount: 'Số lần quẹo hẻm',
        frontHomeAlleyWidth: 'Độ rộng hẻm',
        width: 'Ngang',
        length: 'Dài',
        rear: 'Mặt hậu',
        area: 'Diện tích',
        price: 'Giá',
        floorCount: 'Số tầng',
        constructionArea: 'Diện tích sàn',
        bedroomCount: 'Số phòng ngủ',
        toiletCount: 'Số nhà vệ sinh',
        direction: 'Hướng',
        isNewConstruction: 'Hiện trạng',
        industryTrade: 'Ngành hàng',
        project: 'Dự án',
        hotelStar: 'Số sao',
        buildingType: 'Loại cao ốc',
        warehouseType: 'Chức năng nhà xưởng',
        block: 'Thuộc block/khu nào',
        onFloor: 'Vị trí tầng',
        clearanceArea: 'Diện tích thông thuỷ',
        investor: 'Chủ đầu tư',
        hasMezzanine: 'Có gác hay không',
        commission: 'Phí hoa hồng',
        roomCount: 'Số lượng phòng',
        hasSeaView: 'Có view biển hay không',
        warehouseRoomCount: 'Số phòng kho',
        bedRoomArea: 'Diện tích phòng ngủ',
      },
    },
    detailProduct: {
      type: 'Loại',
      fullAddress: 'Địa chỉ',
      houseNumber: 'Mã căn',
      hasAlley: 'Vị trí',
      alleyTurnCount: 'Số lần quẹo hẻm',
      frontHomeAlleyWidth: 'Độ rộng hẻm',
      width: 'Ngang',
      length: 'Dài',
      rear: 'Mặt hậu',
      area: 'Diện tích',
      price: 'Giá',
      floorCount: 'Số tầng',
      constructionArea: 'Diện tích sàn',
      bedroomCount: 'Số phòng ngủ',
      toiletCount: 'Số nhà vệ sinh',
      direction: 'Hướng',
      isNewConstruction: 'Hiện trạng',
      industryTrade: 'Ngành hàng',
      project: 'Dự án',
      hotelStar: 'Số sao',
      buildingType: 'Loại cao ốc',
      warehouseType: 'Chức năng nhà xưởng',
      block: 'Thuộc block/khu nào',
      onFloor: 'Vị trí tầng',
      clearanceArea: 'Diện tích thông thuỷ',
      investor: 'Chủ đầu tư',
      hasMezzanine: 'Có gác hay không',
      commission: 'Phí hoa hồng',
      roomCount: 'Số lượng phòng',
      hasSeaView: 'Có view biển hay không',
      warehouseRoomCount: 'Số phòng kho',
      bedRoomArea: 'Diện tích phòng ngủ',
    },
  },
  mobile: {
    home: {
      banner: {
        slogan: {
          title: 'Kênh thông tin về bất động sản',
          subtile: 'Giúp bạn nhanh chóng tìm được ngôi nhà mà bạn luôn mong ước',
        },
        searchTool: {
          searchModal: {
            forBuy: 'Đang bán',
            forRent: 'Cho thuê',
          },
        },
      },
    },
  },
  major: {
    statusProfile: {
      approved: 'Đã xác thực hồ sơ',
      pending: 'Đang xác thực hồ sơ',
      rejected: 'Bị từ chối',
      normal: 'Chưa xác thực hồ sơ',
    },
    approvalStatus: zipObject(values(ApprovalStatusEnumType), [
      'Đã duyệt',
      'Đang chờ duyệt',
      'Bị từ chối',
    ]),
    tradingStatus: {
      trading: 'Đang giao dịch',
      stop: 'Ngưng giao dịch',
    },
    b2cUserTypes: {
      HomeOwner: 'Chủ nhà',
      Broker: 'Môi giới',
      Company: 'Doanh nghiệp',
    },
    typeOfDemand: {
      ForSale: 'Bán',
      ForRent: 'Cho thuê',
      ToBuy: 'Cần mua',
      ToRent: 'Cần thuê',
    },
    width: {
      lessThanThree: '3 m trở xuống',
      threeToFive: '3 - 5 m',
      fiveToTen: '5 - 10 m',
      tenToTwenty: '10 - 20 m',
      twentyToForty: '20 - 40 m',
      fortyToSixty: '40 - 60 m',
      sixtyToHundred: '60 - 100 m',
      hundredToMax: '100 m trở lên',
    },
    length: {
      lessThanTen: '10 m trở xuống',
      tenToTwenty: '10 - 20 m',
      twentyToForty: '20 - 40 m',
      fortyToEighty: '40 - 80 m',
      eightyToOneHundredSixty: '80 - 160 m',
      oneHundredSixtyToThreeHundreds: '160 - 300 m',
      threeHundredsToFiveHundreds: '300 - 500 m',
      fiveHundredsToMax: '500 m trở lên',
    },
    area: {
      lessThanTen: '10 m\u00B2 trở xuống',
      tenToTwenty: '10 - 20 m\u00B2',
      twentyToForty: '20 - 40 m\u00B2',
      fortyToEighty: '40 - 80 m\u00B2',
      eightyToOneHundredSixty: '80 - 160 m\u00B2',
      oneHundredSixtyToThreeHundreds: '160 - 300 m\u00B2',
      threeHundredsToFiveHundreds: '300 - 500 m\u00B2',
      fiveHunderedsToOneThousand: '500 - 1,000 m\u00B2',
      oneThoundsanTo2Thousand: '1,000 - 2,000 m\u00B2',
      twoThousandTo3Thousand: '2,000 - 3,000 m\u00B2',
      threeThousandTo4Thousand: '3,000 - 4,000 m\u00B2',
      fourThousandTo5Thousand: '4,000 - 5,000 m\u00B2',
      fiveThousandTo20Thousand: '5,000 - 20,000 m\u00B2',
      over20Thousand: '20,000 m\u00B2 trở lên',
    },
    priceForRent: {
      lessThan1M: '1 triệu trở xuống',
      oneMTo3M: '1 triệu - 3 triệu',
      threeMTo5M: '3 triệu - 5 triệu',
      fiveMTo10M: '5 triệu - 10 triệu',
      tenMTo40M: '10 triệu - 40 triệu',
      fortyMTo70M: '40 triệu - 70 triệu',
      seventyMTo100M: '70 triệu - 100 triệu',
      oneHundredMTo200M: '100 triệu - 200 triệu',
      twoHundredsMToMax: '200 triệu trở lên',
    },
    priceForSell: {
      lessThan500M: '500 triệu trở xuống',
      fiveHundredsMTo800Mil: '500 triệu - 800 triệu',
      eightHundredsMTo1B: '800 triệu - 1 tỷ',
      oneBTo2B: '1 tỷ - 2 tỷ',
      twoBTo3B: '2 tỷ - 3 tỷ',
      threeBTo5B: '3 tỷ - 5 tỷ',
      fiveBTo7B: '5 tỷ - 7 tỷ',
      sevenBTo10B: '7 tỷ - 10 tỷ',
      tenBTo20B: '10 tỷ - 20 tỷ',
      twentyBToThirtyB: '20 tỷ - 30 tỷ',
      thirtyBToFiftyB: '30 tỷ - 50 tỷ',
      fiftyBToEightyB: '50 tỷ - 80 tỷ',
      eightyBToHundredB: '80 tỷ - 100 tỷ',
      hundredBToMax: '100 tỷ trở lên',
    },
    bedroomCount: {
      lessThanThreeFloors: '3 phòng trở xuống',
      threeToFiveFloors: '3 - 5 phòng',
      fiveToTenFloors: '5 - 10 phòng',
      tenToTwentyFloors: '10 - 20 phòng',
      twentyToFortyFloors: '20 - 40 phòng',
      fortyToSixtyFloors: '40 - 60 phòng',
      sixtyToEightyFloors: '60 - 80 phòng',
      eightyToHundredFloors: '80 - 100 phòng',
      hundredToHundredTwentyFloors: '100 - 120 phòng',
      hundredTwentyToHundredFortyFloors: '120 - 140 phòng',
      hundredFortyToHundredSixtyFloors: '140 - 160 phòng',
      hundredSixtyToHundredSixtyEightFloors: '160 - 168+ phòng',
    },
    toiletCount: {
      lessThanThreeFloors: '3 phòng trở xuống',
      threeToFiveFloors: '3 - 5 phòng',
      fiveToTenFloors: '5 - 10 phòng',
      tenToTwentyFloors: '10 - 20 phòng',
      twentyToFortyFloors: '20 - 40 phòng',
      fortyToSixtyFloors: '40 - 60 phòng',
      sixtyToEightyFloors: '60 - 80 phòng',
      eightyToHundredFloors: '80 - 100 phòng',
      hundredToHundredTwentyFloors: '100 - 120 phòng',
      hundredTwentyToHundredFortyFloors: '120 - 140 phòng',
      hundredFortyToHundredSixtyFloors: '140 - 160 phòng',
      hundredSixtyToHundredSixtyEightFloors: '160 - 168+ phòng',
    },
    floorCount: {
      lessThanThreeFloors: '3 tầng trở xuống',
      threeToFiveFloors: '3 - 5 tầng',
      fiveToTenFloors: '5 - 10 tầng',
      tenToTwentyFloors: '10 - 20 tầng',
      twentyToFortyFloors: '20 - 40 tầng',
      fortyToSixtyFloors: '40 - 60 tầng',
      sixtyToEightyFloors: '60 - 80 tầng',
      eightyToHundredFloors: '80 - 100 tầng',
      hundredToHundredTwentyFloors: '100 - 120 tầng',
      hundredTwentyToHundredFortyFloors: '120 - 140 tầng',
      hundredFortyToHundredSixtyFloors: '140 - 160 tầng',
      hundredSixtyToHundredSixtyEightFloors: '160 - 168+ tầng',
    },
    postTypes: {
      3: 'Tin thường',
      2: 'Tin VIP',
      1: 'Tin VIP đặc biệt',
    },
    isForSell: {
      true: 'Bán',
      false: 'Cho thuê',
    },
    demand: {
      sell: 'Bán',
      rent: 'Cho thuê',
    },
    hasAlley: {
      true: 'Hẻm',
      false: 'Mặt tiền',
    },
    direction: {
      Dong: 'Đông',
      DongBac: 'Đông Bắc',
      DongNam: 'Đông Nam',
      DongTuTrach: 'Đông Tứ Trạch',
      Nam: 'Nam',
      Tay: 'Tây',
      Bac: 'Bắc',
      TayBac: 'Tây Bắc',
      TayNam: 'Tây Nam',
      TayTuTrach: 'Tây Tứ Trạch',
    },
    forSellRealEstateType: {
      townhouse: 'Nhà mặt phố',
      house: 'Nhà riêng',
      villa: 'Biệt thự, nhà liền kề',
      building: 'Cao ốc, văn phòng',
      hotel: 'Khách sạn',
      warehouse: 'Nhà xưởng, kho, kiot',
      land: 'Đất nền',
      apartment: 'Căn hộ chung cư',
      shophouse: 'Shophouse',
      penthouse: 'Penthouse',
      condotel: 'Condotel',
      farm: 'Trang trại, khu nghỉ dưỡng',
      other: 'Nhà đất khác',
    },
    forRentRealEstateType: {
      townhouse: 'Nhà mặt phố',
      house: 'Nhà riêng',
      villa: 'Biệt thự, nhà liền kề',
      building: 'Cao ốc, văn phòng',
      hotel: 'Khách sạn',
      warehouse: 'Nhà xưởng, kho, kiot',
      land: 'Đất nền',
      apartment: 'Căn hộ chung cư',
      shophouse: 'Shophouse',
      penthouse: 'Penthouse',
      motel: 'Phòng trọ',
      condotel: 'Condotel',
      other: 'Nhà đất khác',
    },
    warehouseType: {
      XCN: 'Xưởng công nghiệp',
      XNN: 'Xưởng nông nghiệp',
      XTP: 'Xưởng thực phẩm',
      XGC: 'Xưởng gia công',
      XSX: 'Xưởng sản xuất',
      XKH: 'Xưởng kho',
    },
    buildingType: {
      typeA: 'Loại A',
      typeB: 'Loại B',
      typeC: 'Loại C',
    },
    isNewConstruction: {
      true: 'Xây mới',
      false: 'Xây cũ',
    },
    fixedUtilities: {
      fireProtection: 'Phòng cháy chữa cháy',
      callCenter: 'Tổng đài',
      hospital: 'Bệnh viện',
      taxi: 'Taxi',
      police: 'Công an phường',
      sportCenter: 'Trung tâm thể thao',
      electricity: 'Điện lực',
    },
    utilities: {
      pool: 'Hồ bơi',
      storehouse: 'Nhà kho',
      garden: 'Sân vườn',
      lift: 'Thang máy',
      smartKey: 'Thẻ ra vào tòa nhà',
    },
    localUtilities: {
      parking: 'Bãi đỗ xe',
      market: 'Chợ',
      hospital: 'Bệnh viện',
      park: 'Công viên',
      pharmacy: 'Hiệu thuốc',
      bank: 'Ngân hàng',
      restaurant: 'Nhà hàng',
      supermarket: 'Siêu thị',
      gym: 'Phòng gym',
      cafe: 'Cà phê',
      mall: 'Trung tâm mua sắm',
    },
    villaViewTypes: {
      sea: 'View Biển',
      city: 'View Phố',
      mountain: 'View Núi',
      inside: 'View nội khu',
      hill: 'View Đồi',
    },
    equipmentsAndServices: {
      internalContactSystem: 'Hệ thống liên lạc tòa nhà',
      waterHeater: 'Máy nước nóng',
      kitchenEquipments: 'Thiết bị nhà bếp',
      fridge: 'Tủ lạnh',
      washingMachine: 'Máy giặt',
      audioDevices: 'Thiết bị âm thanh',
      tv: 'Tivi',
      airConditioner: 'Máy lạnh',
    },
    legals: {
      fj6CRrbDaN: 'Sổ đỏ',
      '5RFyDzsR4F': 'Sổ hồng',
      '2CuWnD9Qdx': 'Bằng khoán',
      NHYzxq89wW: 'Đang thế chấp',
      '4qeqABnYbz': 'Ngân hàng chấp thuận cho vay',
      Z8SRwDegQv: 'Đang chờ bàn giao sổ',
      sq8BYh834D: 'Có hợp đồng thuê',
      RkGsFSqcS2: 'Có đặt cọc',
      zagg65MEwh: 'Có hợp đồng giữ xe',
      YZpgh54N8A: 'Có đăng ký tạm trú, tạm vắng',
    },
    hasMezzanine: {
      true: 'Có',
      false: 'Không',
    },
    constructQualities: {
      rough: 'Xây thô',
      basic: 'Đơn giản',
      convenient: 'Tiện nghi',
      luxurious: 'Sang trọng',
      extremely_luxurious: 'Cực kỳ cao cấp',
    },
    constructionUtilities: {
      pool: 'Có hồ bơi',
      mezzanine: 'Có lửng',
      lift: 'Có thang máy',
      basement: 'Có tầng hầm',
    },
    plusPoints: {
      alleyBehind: 'Có hẻm sau nhà',
      atCornerOrByAlley: 'Căn góc, có hẻm bên hông',
      nearPark: 'Gần công viên, trung tâm giải trí (<500m)',
      aheadIsBigAlley: 'Hẻm thông ra đường khác đẹp hơn',
      goodForCafeOrHotel: 'Tiện làm quán cafe, nhà hàng, khách sạn',
      bestPosition: 'Vị trí đẹp nhất trong đoạn đường',
      twoFrontispieces: 'Có 2 mặt đường chính',
      premiumResidentialArea: 'Khu dân cư cao cấp có cổng bảo vệ',
      nearSupermarket: 'Gần chợ, siêu thị (<500m)',
    },
    minusPoints: {
      oppositeAlley: 'Đường, hẻm đâm thẳng vào nhà',
      sewerFrontOf: 'Có cống trước nhà',
      bigTreeFrontOf: 'Có cây lớn trước nhà',
      pausedPlan: 'Bị quy hoạch treo',
      underBridgeOrFadeOver: 'Dưới chân cầu hoặc dưới đường dây điện cao thế',
      oppositePagodaOrChurch: 'Đối diện hoặc gần sát chùa, nhà thờ',
      polesFrontOf: 'Có trụ điện trước nhà',
      nearMorgueOrFuneralHouse: 'Đối diện hoặc gần sát nhà tang lễ, nhà xác',
    },
    landUsage: {
      residentialLand: 'Đất ở',
      productionForests: 'Đất rừng sản xuất',
      garden: 'Đất vườn',
      aquacultureLand: 'Đất nuôi trồng thủy sản',
      agriculturalLand: 'Đất nông nghiệp',
      commercialLand: 'Đất thương mại dịch vụ',
      perennialTreeLand: 'Đất trồng cây lâu năm',
      educationLand: 'Đất giáo dục',
    },
    buildingUtility: {
      pool: 'Có hồ bơi',
      lift: 'Có thang máy',
    },
    projectStatus: {
      onSale: 'Đang mở bán',
      constructingFoundation: 'Đang khởi công móng',
      completedFiftyPercent: 'Đã hoàn thành 50%',
      roofed: 'Đã cất nóc',
    },
    saleStatus: {
      onSale: 'Đang bán',
      reserved: 'Giữ chỗ',
      confirmed: 'Đã đặt chỗ',
      sold: 'Đã bán',
      transferred: 'Đã bàn giao',
      notOnSale: 'Chưa mở bán',
      stopSelling: 'Đã ngưng bán',
    },
    projectTotalArea: {
      under1000m2: '< 1000 m\u00B2',
      '1000m2to2000m2': '1000 - 2000 m\u00B2',
      '2000m2to3000m2': '2000 - 3000 m\u00B2',
      '3000m2to4000m2': '3000 - 4000 m\u00B2',
      '4000m2to4000m2': '4000 - 5000 m\u00B2',
      over5000m2: '> 5000 m\u00B2',
    },
    projectLegals: {
      ownershipCertificate: 'Sổ đỏ',
      bankSupport: 'Có ngân hàng hỗ trợ vay',
      followInvestorContract: 'Mua bán theo hợp đồng chủ đầu tư',
      followContributionContract: 'Mua bán theo hợp đồng góp vốn',
      other: 'Khác',
    },
    projectType: {
      complex: 'Bán dự án phức hợp',
      landProject: 'Bán dự án đất nền',
      industrialArea: 'Bán dự án khu công nghiệp',
      buildingProject: 'Bán dự án cao ốc văn phòng',
      shoppingMall: 'Bán dự án trung tâm thương mại',
      socialHouse: 'Nhà ở xã hội',
    },
    priceRange: {
      under500: '< 500 ngàn/m\u00B2',
      '500to1000': '500 ngàn - 1 triệu/m\u00B2',
      '1000to2000': '1 - 2 triệu/m\u00B2',
      '2000to3000': '2 - 3 triệu/m\u00B2',
      '3000to4000': '4 - 5 triệu/m\u00B2',
      '5000to10000': '5 - 10 triệu/m\u00B2',
      '10000to20000': '10 - 20 triệu/m\u00B2',
      '20000to30000': '20 - 30 triệu/m\u00B2',
      '30000to50000': '30 - 50 triệu/m\u00B2',
      '50000to70000': '50 - 70 triệu/m\u00B2',
      '70000to100000': '70 - 100 triệu/m\u00B2',
      '100000to200000': '100 - 200 triệu/m\u00B2',
      '200000to300000': '200 - 300 triệu/m\u00B2',
      '300000to500000': '300 - 500 triệu/m\u00B2',
      over500000: '> 500 triệu/m\u00B2',
    },
    complexProjectType: {
      complex: 'Dự án phức hợp',
      socialHouse: 'Dự án nhà ở xã hội',
      newUrban: 'Dự án khu đô thị mới',
      resort: 'Dự án nghỉ dưỡng',
    },
    constructionForm: {
      freedom: 'Xây cất tự do',
      rules: 'Xây cất theo quy định của chủ đầu tư',
    },
    projectViews: {
      seaView: 'View biển',
      cityView: 'View phố',
      mountainView: 'View đồi',
      localView: 'View nội khu',
    },
    paymentType: {
      reservation: 'Tiền đặt chỗ',
      deposit: 'Tiền đặt cọc',
      paymentInStages: 'Tiền thanh toán theo giao đoạn',
      ninetyEightPercentFinalization: 'Tiền tất toán 98% hợp đồng',
      handingOverCertificate: 'Bàn giao sổ & nhận 2% cuối',
    },
    seeHouseDemand: {
      seeHouseLive: 'Xem nhà trực tiếp',
      seeLegalInformation: 'Xem thông tin pháp lý',
      seeLiveStream: 'Xem nhà livestream',
      getDocumentProject: 'Nhận tài liệu dự án',
    },
  },
};

export default vi;
