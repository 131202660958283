import { useReactiveVar } from '@apollo/client';
import { find, isEqual } from 'lodash';
import moment from 'moment-timezone';
import Image from 'next/image';
import { selectedConversationVar } from '../../../../../../../../apollo/store';
import { Message as MessageModel } from '../../../../../../../../graphql/main/queries/messages';
import MessageAction from './message-action';

interface Props {
  message?: MessageModel;
  isOwner?: boolean;
}

const Message = ({ message, isOwner }: Props) => {
  const selectedConversation = useReactiveVar(selectedConversationVar);

  return (
    <div
      className={`group flex items-center space-x-[10px] py-[8px] ${
        isOwner ? 'flex-row-reverse' : 'flex-row'
      }`}
    >
      {!isOwner && (
        <div className='relative h-[20px] w-[20px] overflow-hidden rounded-full'>
          <Image
            src={
              find(selectedConversation?.participants, (participant) =>
                isEqual(participant.userId, message?.from),
              )?.avatar?.originalUrl ?? '/images/default-avatar.svg'
            }
            alt={
              find(selectedConversation?.participants, (participant) =>
                isEqual(participant.userId, message?.from),
              )?.avatar?.originalUrl ?? '/images/default-avatar.svg'
            }
            layout='fill'
            objectFit='cover'
          />
        </div>
      )}
      <div className={`flex flex-col ${isOwner ? 'items-end' : ''}`}>
        <div
          className={`max-w-[240px] space-y-[10px] rounded-[14px] px-[12px] py-[6px] ${
            isOwner ? 'bg-secondary' : 'bg-[#eeeeee]'
          }`}
        >
          <span className='whitespace-pre-wrap break-words'>{message?.text}</span>
          <span className='block text-[10px] leading-[13px]'>
            {moment(message?.createdAt).format('LT')}
          </span>
        </div>
      </div>
      <MessageAction id={message?.id} />
    </div>
  );
};

export default Message;
