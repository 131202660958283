import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export const useResponsive = () => {
  const [isClient, setIsClient] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 1129 });
  const isDesktop = useMediaQuery({ minWidth: 1130 });

  useEffect(() => {
    if (!isEqual(typeof window, 'undefined')) {
      setIsClient(true);
    }
  }, []);

  return isClient ? (isMobile ? 'mobile' : isDesktop ? 'desktop' : null) : null;
};
