import { gql } from '@apollo/client';
import { District } from './get-list-districts-of-province';
import { DateTime, Float, ObjectID, String } from '../../main/queries/get-real-estates';

export interface Province {
  createdAt?: DateTime;
  districts?: District[];
  id: ObjectID;
  lat?: Float;
  long?: Float;
  name?: String;
  shortname?: String;
  updatedAt?: DateTime;
}

export interface GetListProvincesData {
  getListProvinces?: Province[];
}

export const GET_LIST_PROVINCES = gql`
  query getListProvinces {
    getListProvinces {
      id
      name
    }
  }
`;
