import { Popover } from '@headlessui/react';
import { useFlexMutation } from 'hooks';
import { map, toString } from 'lodash';
import { TbDots } from 'react-icons/tb';
import {
  REMOVE_CONVERSATION,
  RemoveConversationData,
  RemoveConversationVariables,
} from '../../../../../../../../../graphql/main/mutations/remove-conversation';

interface Props {
  isGroup?: boolean;
  id?: string;
}

const ConversationAction = ({ isGroup, id }: Props) => {
  const [mutationRemoveConversation] = useFlexMutation<
    RemoveConversationData,
    RemoveConversationVariables
  >('main', REMOVE_CONVERSATION);

  const handleOnPinConversation = () => {};
  const handleOnChangeConversationName = () => {};
  const handleOnViewProfile = () => {};
  const handleOnCreateMessage = () => {};
  const handleOnViewMediaFiles = () => {};
  const handleOnBlockUser = () => {};
  const handleOnAddMember = () => {};
  const handleOnRemoveMember = () => {};
  const handleOnClearMessages = () => {};
  const handleOnLeaveGroup = () => {};
  const handleOnDisbandGroup = () => {
    mutationRemoveConversation({ variables: { conversationId: toString(id) } });
  };

  const privateMessageActions = [
    {
      label: 'Đổi biệt hiệu',
      onClick: () => handleOnChangeConversationName(),
    },
    {
      label: 'Xem trang cá nhân',
      onClick: () => handleOnViewProfile(),
    },
    {
      label: 'Tạo tin nhắn với',
      onClick: () => handleOnCreateMessage(),
    },
    {
      label: 'Xem lịch sử hình ảnh, video',
      onClick: () => handleOnViewMediaFiles(),
    },
    {
      label: 'Chặn người dùng này',
      onClick: () => handleOnBlockUser(),
    },
  ];
  const groupMessageActions = [
    {
      label: 'Đổi tên nhóm',
      onClick: () => handleOnChangeConversationName(),
    },
    {
      label: 'Cập nhật thành viên',
      onClick: () => handleOnAddMember(),
    },
    {
      label: 'Xóa thành viên',
      onClick: () => handleOnRemoveMember(),
    },
    {
      label: 'Xem lịch sử hình ảnh, video',
      onClick: () => handleOnViewMediaFiles(),
    },
  ];

  return (
    <Popover id='conversation-action' className='relative'>
      <Popover.Button type='button'>
        <TbDots className='min-h-[16px] min-w-[16px] font-[600] text-text2' />
      </Popover.Button>
      <Popover.Panel>
        <div className='absolute right-0 z-[1] w-[200px] divide-y divide-stroke rounded-[8px] bg-paper shadow-4'>
          <button
            type='button'
            className='flex h-[38px] w-full items-center px-[10px] transition duration-[200ms] ease-in-out hover:bg-secondary'
            onClick={handleOnPinConversation}
          >
            <span>Ghim cuộc hội thoại</span>
          </button>
          <div>
            {isGroup
              ? map(groupMessageActions, (action, actionIndex) => (
                  <button
                    key={actionIndex}
                    type='button'
                    className='flex h-[38px] w-full items-center px-[10px] transition duration-[200ms] ease-in-out hover:bg-secondary'
                    onClick={action.onClick}
                  >
                    {action.label}
                  </button>
                ))
              : map(privateMessageActions, (action, actionIndex) => (
                  <button
                    key={actionIndex}
                    type='button'
                    className='flex h-[38px] w-full items-center px-[10px] transition duration-[200ms] ease-in-out hover:bg-secondary'
                    onClick={action.onClick}
                  >
                    {action.label}
                  </button>
                ))}
          </div>
          <button
            type='button'
            className='flex h-[38px] w-full items-center px-[10px] transition duration-[200ms] ease-in-out hover:bg-secondary'
            onClick={handleOnClearMessages}
          >
            <span>Xóa tất cả tin nhắn</span>
          </button>
          {isGroup && (
            <div>
              <button
                type='button'
                className='flex h-[38px] w-full items-center px-[10px] text-alert transition duration-[200ms] ease-in-out hover:bg-secondary'
                onClick={handleOnLeaveGroup}
              >
                Rời khỏi nhóm
              </button>
              <button
                type='button'
                className='flex h-[38px] w-full items-center px-[10px] text-alert transition duration-[200ms] ease-in-out hover:bg-secondary'
                onClick={handleOnDisbandGroup}
              >
                Giải tán nhóm
              </button>
            </div>
          )}
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default ConversationAction;
