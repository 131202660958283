import { useReactiveVar } from '@apollo/client';
import { useAuthenticationContext } from 'components/providers/authentication-provider';
import { find, gt, includes, isEqual, toString } from 'lodash';
import Image from 'next/image';
import { useId } from 'react';
import { convertMessageCreatedAt, getGroupNameFromParticipants } from 'utils';
import { selectedConversationVar } from '../../../../../../../../apollo/store';
import { Conversation } from '../../../../../../../../graphql/main/queries/conversations';
import ConversationAction from './conversation-action';

interface Props {
  conversation?: Conversation;
}

const Conversation = ({ conversation }: Props) => {
  const { currentUser } = useAuthenticationContext();
  const selectedConversation = useReactiveVar(selectedConversationVar);
  const id = useId();

  const handleOnSelectConversation = () => {
    selectedConversationVar(conversation);
  };

  return (
    <div
      id={id}
      className={`relative flex h-[63px] w-full cursor-pointer items-center justify-between space-x-[8px] p-[12px] transition duration-[200ms] ease-in-out hover:bg-secondary ${
        isEqual(selectedConversation?.id, conversation?.id) ? 'bg-secondary' : ''
      }`}
      onClick={handleOnSelectConversation}
    >
      {/* {conversation?.participants ? (
        isNull(
          conversation?.participants[
            findIndex(conversation?.participants, { userId: currentUser?.id })
          ].pinnedAt,
        )
      ) : null ? (
        <div className='absolute top-0 right-[36px] h-[20px] w-[16px] bg-[url("/icons/bookmark.svg")] bg-cover bg-center bg-no-repeat' />
      ) : null} */}
      {conversation?.isGroup ? (
        <div className='flex h-full items-center space-x-[12px]'>
          <div className='relative h-[32px] w-[32px]'>
            <div className='absolute top-0 right-0 h-[20px] w-[20px] overflow-hidden rounded-full'>
              <Image
                src={
                  conversation?.participants?.[0].avatar?.originalUrl ??
                  '/images/default-avatar.svg'
                }
                alt={
                  conversation?.participants?.[0].avatar?.originalUrl ??
                  '/images/default-avatar.svg'
                }
                layout='fill'
                objectFit='cover'
                placeholder='blur'
                blurDataURL='/images/image-loader.svg'
              />
            </div>
            <div className='absolute bottom-0 box-content h-[20px] w-[20px] overflow-hidden rounded-full border-[2px] border-paper'>
              <Image
                src={
                  conversation?.participants?.[1].avatar?.originalUrl ??
                  '/images/default-avatar.svg'
                }
                alt={
                  conversation?.participants?.[1].avatar?.originalUrl ??
                  '/images/default-avatar.svg'
                }
                layout='fill'
                objectFit='cover'
                placeholder='blur'
                blurDataURL='/images/image-loader.svg'
              />
            </div>
          </div>
          <div className='flex h-full max-w-[128px] flex-col justify-center space-y-[4px]'>
            <span className='truncate font-[600]'>{conversation.name}</span>
            <span
              className={`truncate text-[12px] leading-[15px] ${
                includes(conversation.lastMessage?.seenBy, currentUser?.id) ||
                isEqual(currentUser?.id, conversation.lastMessage?.from)
                  ? isEqual(selectedConversation?.id, conversation.id)
                    ? 'text-placeholder'
                    : 'text-text2'
                  : 'font-[600]'
              } ${
                isEqual(selectedConversation?.id, conversation.id)
                  ? 'text-placeholder'
                  : includes(conversation.lastMessage?.seenBy, currentUser?.id)
                  ? 'text-text2'
                  : isEqual(currentUser?.id, conversation.lastMessage?.from)
                  ? ''
                  : 'text-text2'
              }`}
            >
              {`${isEqual(currentUser?.id, conversation.lastMessage?.from) ? 'Bạn: ' : ''}${
                conversation.lastMessage?.text
              }`}
            </span>
          </div>
        </div>
      ) : (
        <div className='flex h-full items-center space-x-[12px]'>
          <div className='relative h-[32px] w-[32px] overflow-hidden rounded-full'>
            <Image
              src={
                find(
                  conversation?.participants,
                  (participant) => !isEqual(participant.userId, currentUser?.id),
                )?.avatar?.originalUrl ?? '/images/default-avatar.svg'
              }
              alt={
                find(
                  conversation?.participants,
                  (participant) => !isEqual(participant.userId, currentUser?.id),
                )?.avatar?.originalUrl ?? '/images/default-avatar.svg'
              }
              layout='fill'
              objectFit='cover'
              placeholder='blur'
              blurDataURL='/images/image-loader.svg'
            />
          </div>
          <div className='flex h-full max-w-[128px] flex-col justify-center space-y-[4px]'>
            <span className='truncate font-[600]'>
              {getGroupNameFromParticipants(toString(currentUser?.id), conversation?.participants)}
            </span>
            {conversation?.lastMessage?.text && (
              <span
                className={`truncate text-[12px] leading-[15px] ${
                  includes(conversation.lastMessage.seenBy, currentUser?.id) ||
                  isEqual(currentUser?.id, conversation.lastMessage.from)
                    ? isEqual(selectedConversation?.id, conversation.id)
                      ? 'text-placeholder'
                      : 'text-text2'
                    : 'font-[600]'
                }`}
              >
                {`${isEqual(currentUser?.id, conversation.lastMessage.from) ? 'Bạn: ' : ''}${
                  conversation.lastMessage.text
                }`}
              </span>
            )}
          </div>
        </div>
      )}
      <div className='flex h-full flex-col items-end justify-center space-y-[4px]'>
        <ConversationAction
          isGroup={gt(conversation?.participants?.length, 2)}
          id={conversation?.id}
        />
        <span
          className={`text-[12px] leading-[15px] ${
            includes(conversation?.lastMessage?.seenBy, currentUser?.id) ||
            isEqual(currentUser?.id, conversation?.lastMessage?.from)
              ? isEqual(selectedConversation?.id, conversation?.id)
                ? 'text-placeholder'
                : 'text-text2'
              : 'font-[600]'
          } ${
            isEqual(selectedConversation?.id, conversation?.id)
              ? 'text-placeholder'
              : includes(conversation?.lastMessage?.seenBy, currentUser?.id)
              ? 'text-text2'
              : isEqual(currentUser?.id, conversation?.lastMessage?.from)
              ? ''
              : 'text-text2'
          }`}
        >
          {conversation?.lastMessage?.createdAt
            ? convertMessageCreatedAt(conversation?.lastMessage?.createdAt)
            : ''}
        </span>
      </div>
    </div>
  );
};

export default Conversation;

export const ConversationSkeleton = () => (
  <div className='flex h-[63px] w-full animate-pulse items-center justify-between p-[12px]'>
    <div className='flex space-x-[12px]'>
      <div className='relative'>
        <div className='relative h-[32px] w-[32px] overflow-hidden rounded-full bg-secondary'></div>
      </div>
      <div className='flex max-w-[128px] flex-1 flex-col space-y-[4px]'>
        <div className='min-h-[14px] min-w-[90px] max-w-[50px] bg-secondary'></div>
        <div className='min-h-[12px] min-w-[180px] max-w-[90px] bg-secondary'></div>
      </div>
    </div>
  </div>
);
