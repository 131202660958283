import { gql } from '@apollo/client';
import { Boolean, Float, String } from './get-real-estates';
import { RangeValueType } from './list-projects-atmnha';
import { Int } from './get-real-estates';

export interface KeyValueType {
  key?: String;
  value?: String;
  fee?: Float;
  sale?: Boolean;
  isForRent?: Boolean;
  isForSell?: Boolean;
  estateType?: String[];
}
export interface InternalRealEstateParameterType {
  area?: RangeValueType[];
  width?: RangeValueType[];
  length?: RangeValueType[];
  priceToBuy?: RangeValueType[];
  priceToRent?: RangeValueType[];
  priceToRentByDay?: RangeValueType[];
  priceToRentByMonth?: RangeValueType[];
  floorCount?: RangeValueType[];
  floorPosition?: RangeValueType[];
  roomCount?: RangeValueType[];
  toiletCount?: RangeValueType[];
  bedroomCount?: RangeValueType[];
  villaView?: RangeValueType[];
  hotelStar?: RangeValueType[];
  timeToRent?: RangeValueType[];
  timeForRentUnits?: RangeValueType[];
}
export interface ForSellAndRentREConfigurationInternalType {
  forRentRealEstateTypes?: KeyValueType[];
  forSellRealEstateTypes?: KeyValueType[];
  postTypes?: KeyValueType[];
  priceUnitsForRent?: KeyValueType[];
  priceUnitsForSell?: KeyValueType[];
  legals?: KeyValueType[];
  directions?: KeyValueType[];
  fixedUtilities?: KeyValueType[];
  utilities?: KeyValueType[];
  localUtilities?: KeyValueType[];
  equipmentsAndServices?: KeyValueType[];
  timeForRentUnits?: KeyValueType[];
  warehouseType?: KeyValueType[];
  projectForSaleTypes?: KeyValueType[];
  projectForRentTypes?: KeyValueType[];
  projectSubTypes?: KeyValueType[];
  projectLegals?: KeyValueType[];
  projectViews?: KeyValueType[];
  collectionTypes?: KeyValueType[];
  realEstateForSaleStatus?: KeyValueType[];
  realEstateForRentStatus?: KeyValueType[];
  realEstateForSaleAndRentStatus?: KeyValueType[];
  realEstateTypeOfDemandType?: KeyValueType[];
  villaViewTypes?: KeyValueType[];
  neededRealEstateTradingStatus?: KeyValueType[];
  projectStatusType?: KeyValueType[];
  productStatusForSell?: KeyValueType[];
  productStatusForRent?: KeyValueType[];
}
export interface TypeOfInternalRealEstateType {
  townhouse?: InternalRealEstateParameterType;
  apartment?: InternalRealEstateParameterType;
  shophouse?: InternalRealEstateParameterType;
  villa?: InternalRealEstateParameterType;
  penthouse?: InternalRealEstateParameterType;
  land?: InternalRealEstateParameterType;
  building?: InternalRealEstateParameterType;
  hotel?: InternalRealEstateParameterType;
  warehouse?: InternalRealEstateParameterType;
  motel?: InternalRealEstateParameterType;
  project?: InternalRealEstateParameterType;
}
export interface TypeOfInternalRealEstateFilterType {
  common?: InternalRealEstateParameterType;
  townhouse?: InternalRealEstateParameterType;
  apartment?: InternalRealEstateParameterType;
  shophouse?: InternalRealEstateParameterType;
  villa?: InternalRealEstateParameterType;
  penthouse?: InternalRealEstateParameterType;
  land?: InternalRealEstateParameterType;
  building?: InternalRealEstateParameterType;
  hotel?: InternalRealEstateParameterType;
  warehouse?: InternalRealEstateParameterType;
  motel?: InternalRealEstateParameterType;
  project?: InternalRealEstateParameterType;
}
export interface ProjectFilter {
  projectType?: KeyValueType[];
  priceRange?: RangeValueType[];
  projectStatus?: KeyValueType[];
  handOverYear?: Int[];
  projectTotalArea?: RangeValueType[];
}
export interface InternalRealEstateConfigurationType {
  common?: ForSellAndRentREConfigurationInternalType;
  neededRealEstate?: TypeOfInternalRealEstateType;
  filter?: TypeOfInternalRealEstateFilterType;
  projectFilter?: ProjectFilter;
}

export interface GetInternalRealEstateConfigurationsData {
  getInternalRealEstateConfigurations?: InternalRealEstateConfigurationType;
}

export const GET_INTERNAL_REAL_ESTATE_CONFIGURATIONS = gql`
  query getInternalRealEstateConfigurations @api(name: biha) {
    getInternalRealEstateConfigurations {
      projectFilter {
        projectType {
          key
        }
        priceRange {
          key
          from
          to
        }
        projectStatus {
          key
        }
        projectTotalArea {
          key
          from
          to
        }
        handOverYear
      }
    }
  }
`;
