import { ApolloClient, ApolloLink } from '@apollo/client';
import { baseClientOption } from 'apollo/client';
import { createAuthenticationLink, errorLink, mainLink } from 'apollo/link';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { B2CUser } from 'graphql/main/queries/get-representative-brokers';
import { USER, UserData } from 'graphql/main/queries/user';
import { toString } from 'lodash';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

export interface AuthenticationModel {
  accessToken?: string;
  refreshToken?: string;
}

interface Props {
  authentication?: AuthenticationModel;
  saveAuthentication?: (authentication?: AuthenticationModel) => void;
  currentUser?: B2CUser;
  setCurrentUser?: Dispatch<SetStateAction<B2CUser | undefined>>;
  signOut?: () => void;
}

const AuthenticationContext = createContext<Props>({});

export const useAuthenticationContext = () => useContext(AuthenticationContext);

const AuthenticationProvider = ({ children }: PropsWithChildren) => {
  const [authentication, setAuthentication] = useState<AuthenticationModel | undefined>(() => {
    const authenticationString = toString(getCookie('authentication')) || '{}';
    return JSON.parse(authenticationString) as AuthenticationModel;
  });
  const { accessToken } = authentication ?? {};
  const [currentUser, setCurrentUser] = useState<B2CUser | undefined>();
  const didRequest = useRef(false);

  const saveAuthentication = (authentication?: AuthenticationModel) => {
    setAuthentication(authentication);
    if (authentication) {
      setCookie('authentication', JSON.stringify(authentication));
    } else {
      deleteCookie('authentication');
    }
  };
  const signOut = () => {
    saveAuthentication(undefined);
    setCurrentUser(undefined);
  };

  useEffect(() => {
    const requestUser = async (accessToken: string) => {
      if (!didRequest.current) {
        const authLink = createAuthenticationLink(accessToken);
        const mainClient = new ApolloClient({
          ...baseClientOption,
          link: ApolloLink.from([errorLink, authLink, mainLink]),
        });
        const { data: userData, error: userError } = await mainClient.query<UserData>({
          query: USER,
        });
        if (userError) {
          signOut?.();
        } else {
          const { user } = userData;
          setCurrentUser?.(user);
        }
      }

      return () => (didRequest.current = true);
    };

    if (accessToken) {
      requestUser(accessToken);
    } else {
      signOut?.();
    }
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{ authentication, saveAuthentication, currentUser, setCurrentUser, signOut }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
